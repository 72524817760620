<template>
  <div>
    <div class="demo-spin-article">
      <div class="mb-3 row">
        <div class="col-md-4 tw-px-1">
          <label class="form-label col-label required tw-text-xs">{{
            $t("overtimeRuleByShift.shiftName")
          }}</label>
          <div>
            <Select
              v-model="model.record_id"
              @on-change="onShiftChange()"
              :class="{ 'is-invalid': errors.has('record_id') }"
            >
              <Option
                v-for="(shiftWorkingHour, index) in shiftWorkingHours"
                :value="shiftWorkingHour.record_id"
                :key="index"
              >
                {{ shiftWorkingHour.shift_name }}
              </Option>
            </Select>
            <div class="invalid-feedback" v-if="errors.has('record_id')">
              {{ errors.first("record_id") }}
            </div>
          </div>
        </div>
        <div class="col-md-4 tw-px-1">
          <label
            class="form-label col-label tw-text-xs"
            :class="{ required: shiftType == 'Rotation' }"
            >{{ $t("overtimeRuleByShift.keyword") }}</label
          >
          <div>
            <Input v-model="keyword" type="text" class="tw-w-full" disabled />
          </div>
        </div>
        <div class="col-md-4 tw-px-1">
          <label class="form-label col-label required tw-text-xs">{{
            $t("overtimeRuleByShift.otName")
          }}</label>
          <div>
            <Select
              v-model="model.ot_id"
              :class="{ 'is-invalid': errors.has('ot_id') }"
            >
              <Option
                v-for="(overtimeItem, index) in overtimeItems"
                :value="overtimeItem.ot_id"
                :key="index"
              >
                {{ overtimeItem.ot_name }}
              </Option>
            </Select>
            <div class="invalid-feedback" v-if="errors.has('ot_id')">
              {{ errors.first("ot_id") }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-md-6 tw-px-1">
          <label class="form-label col-label tw-text-xs">{{
            $t("overtimeRuleByShift.dayFrom")
          }}</label>
          <div>
            <Input v-model="dayFrom" type="text" class="tw-w-full" disabled />
          </div>
        </div>
        <div class="col-md-6 tw-px-1">
          <label class="form-label col-label required tw-text-xs">{{
            $t("overtimeRuleByShift.timeFrom")
          }}</label>
          <div>
            <TimePicker
              v-model="model.time_from"
              format="HH:mm"
              placeholder="Select Time From"
              confirm
              class="tw-w-full"
              :class="{ 'is-invalid': errors.has('time_from') }"
            />
            <div class="invalid-feedback" v-if="errors.has('time_from')">
              {{ errors.first("time_from") }}
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-md-6 tw-px-1">
          <label class="form-label col-label tw-text-xs">{{
            $t("overtimeRuleByShift.dayTo")
          }}</label>
          <div>
            <Input v-model="dayTo" type="text" class="tw-w-full" disabled />
          </div>
        </div>
        <div class="col-md-6 tw-px-1">
          <label class="form-label col-label required tw-text-xs">{{
            $t("overtimeRuleByShift.timeTo")
          }}</label>
          <div>
            <TimePicker
              v-model="model.time_to"
              format="HH:mm"
              confirm
              placeholder="Select Time To"
              class="tw-w-full"
              :class="{ 'is-invalid': errors.has('time_to') }"
            />
            <div class="invalid-feedback" v-if="errors.has('time_to')">
              {{ errors.first("time_to") }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <label class="form-label col-label tw-text-xs">{{
          $t("overtimeRuleByShift.remarks")
        }}</label>
        <div>
          <Input
            v-model="model.remarks"
            type="textarea"
            class="tw-w-full"
            :rows="3"
          />
        </div>
      </div>
      <div slot="footer">
        <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh mt-3">
          <ts-button @click.prevent="() => $emit('cancel')" class="btn-gray">
            {{ $t("cancel") }}</ts-button
          >
          <ts-button
            v-if="!isUpdate"
            color="primary"
            outline
            :waiting="waiting_new"
            :disabled="waiting_new || waiting"
            @click.prevent="onSaveAddNew"
          >
            {{ $t("saveAddNew") }}</ts-button
          >
          <ts-button
            color="primary"
            v-if="!isUpdate"
            :waiting="waiting"
            :disabled="waiting_new || waiting"
            @click.prevent="onSave"
          >
            {{ $t("save") }}</ts-button
          >
          <ts-button
            v-if="isUpdate"
            color="primary"
            @click.prevent="onUpdate"
            :waiting="waiting"
          >
            {{ $t("update") }}</ts-button
          >
        </div>
      </div>
    </div>
    <Spin fix v-if="loading"></Spin>
  </div>
</template>
<script>
import { Errors } from "form-backend-validation";
import { mapState, mapActions } from "vuex";
import { isEmpty } from "lodash";

export default {
  nama: "payroll-cycle-form",

  data() {
    return {
      errors: new Errors(),
      loading: false,
      waiting: false,
      waiting_new: false,
      model: {
        record_id: null,
        shift_id: null,
        ot_id: null,
        time_from: null,
        time_to: null,
        remarks: null,
      },
    };
  },
  computed: {
    ...mapState("payroll/overtimeRuleByShift", [
      "edit_data",
      "shiftWorkingHours",
      "overtimeItems",
    ]),
    isUpdate() {
      return !isEmpty(this.edit_data);
    },
    dayFrom() {
      if (this.model.record_id) {
        return this.shiftWorkingHours.find(
          (el) => el.record_id == this.model.record_id
        ).day_from;
      }
      return null;
    },
    dayTo() {
      if (this.model.record_id) {
        return this.shiftWorkingHours.find(
          (el) => el.record_id == this.model.record_id
        ).day_to;
      }
      return null;
    },
    keyword() {
      if (this.model.record_id) {
        return this.shiftWorkingHours.find(
          (el) => el.record_id == this.model.record_id
        ).keyword;
      }
      return null;
    },
    shiftType() {
      if (this.model.record_id) {
        return this.shiftWorkingHours.find(
          (el) => el.record_id == this.model.record_id
        ).shift_type;
      }
      return "";
    },
  },
  methods: {
    ...mapActions("payroll/overtimeRuleByShift", [
      "getShiftWorkingHours",
      "getOvertimeItems",
    ]),
    async fetchResource() {
      this.loading = true;
      await this.getShiftWorkingHours();
      await this.getOvertimeItems();
      this.loading = false;
    },
    onShiftChange() {
      if (this.model.record_id) {
        this.model.shift_id = this.shiftWorkingHours.find(
          (el) => el.record_id == this.model.record_id
        ).shift_id;
      }
    },
    async onSave() {
      this.errors = new Errors();
      this.waiting = true;
      this.$store
        .dispatch("payroll/overtimeRuleByShift/store", this.model)
        .then((response) => {
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
          this.$emit("cancel");
          this.$emit("fetchData");
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    async onSaveAddNew() {
      this.errors = new Errors();
      this.waiting_new = true;
      this.$store
        .dispatch("payroll/overtimeRuleByShift/store", this.model)
        .then((response) => {
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
          this.$emit("fetchData");
          this.clearInput();
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.waiting_new = false;
        });
    },
    onUpdate() {
      this.waiting = true;
      this.$store
        .dispatch("payroll/overtimeRuleByShift/update", {
          id: this.edit_data.id,
          data: this.model,
        })
        .then((response) => {
          this.notice({
            type: response.message_id == 0 ? "success" : "warning",
            text: response.message,
          });
          this.$emit("cancel");
          this.$emit("fetchData");
        })
        .catch((error) => {
          this.errors = new Errors(error.errors);
          this.notice({ type: "error", text: error.message });
        })
        .finally(() => {
          this.waiting = false;
        });
    },
    async setEditData() {
      this.fetchResource();
      if (!isEmpty(this.edit_data)) {
        this.model.record_id = this.edit_data.record_id;
        this.model.shift_id = this.edit_data.shift_id;
        this.model.ot_id = this.edit_data.ot_id;
        this.model.time_from = this.edit_data.time_from;
        this.model.time_to = this.edit_data.time_to;
        this.model.remarks = this.edit_data.remarks;
      }
    },
    clearInput() {
      this.errors = new Errors();
      this.model.record_id = null;
      this.model.shift_id = null;
      this.model.ot_id = null;
      this.model.time_from = null;
      this.model.time_to = null;
      this.model.remarks = null;
    },
    notice(not) {
      this.$Notice[not.type]({
        title: "OVERTIME RULE BY SHIFT",
        desc: not.text,
      });
    },
  },
};
</script>
